import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import MenuItem from "../components/MenuItem";
import Menu from "../components/Menu";
import Title from "../components/Title";
import MenuLayout from "../layouts/screenmenu";
import isToday from "date-fns/isToday";

interface MenuQueryProps {
  data: {
    cms: {
      menu: {
        title: string;
        beschreibung: string;
        bistroNummer: string;
        titelMitOrangeBold: string;
        menuMatrix: [
          {
            bild: [
              {
                url: string;
              }
            ];
            gericht: string;
            datum: string;
            beschreibung: string;
            preis: string;
            menuTyp: string;
          }
        ];
      };
    };
  };
}
export const MenuQuery = graphql`
  query {
    cms {
      menu: entry(slug: "menu-bistro-z-6") {
        title
        ... on CMS_main_menu_Entry {
          beschreibung
          bistroNummer
          titelMitOrangeBold
          menuMatrix {
            ... on CMS_menuMatrix_gericht_BlockType {
              bild {
                url
              }
              gericht
              datum
              beschreibung
              preis
              menuTyp
            }
          }
        }
      }
    }
  }
`;

const MenuPage: React.FC<MenuQueryProps> = ({ data }) => {
  const [todaysMenu, setTodaysMenu] = useState([]);

  const getMenu = () => {
    const menu = data.cms.menu.menuMatrix.filter((item) => {
      const itemDate = new Date(item.datum);

      if (isToday(itemDate)) {
        return item;
      }
    });
    setTodaysMenu(menu);
  };

  useEffect(() => {
    getMenu();
    setInterval(() => {
      window.location.href = window.location.href;
    }, 1000 * 60 * 60 * 4);
  }, []);

  // useEffect(() => )

  return (
    <MenuLayout>
      <Title text={data.cms.menu.titelMitOrangeBold} topOffset />
      <Menu>
        {todaysMenu.length > 0
          ? todaysMenu.map((entry) => (
              <>
                <MenuItem entry={entry} />
              </>
            ))
          : ""}
      </Menu>
    </MenuLayout>
  );
};

export default MenuPage;

import React from "react";
import styled from "styled-components";

import "normalize.css";
import "../styles/fonts.css";
import "../styles/base.css";

import LayoutMain from "../components/LayoutMain";
// import { relative } from "path";

const FlexGrow = styled.div`
  /* display: flex;
  flex-direction: column; */
  //flex: 1;
  width: 100%;
`;

const MenuLayout: React.FC<MenuLayout> = ({ children }) => {
  return (
    <FlexGrow>
      <div
        style={{
          width: "100%",
          height: "100vh",
          maxWidth: 1280,
          padding: 20,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          transform: "scale(66%) translateY(-33.5%)",
        }}
      >
        <LayoutMain>{children}</LayoutMain>
      </div>
    </FlexGrow>
  );
};

export default MenuLayout;
